let menuClicked = false;

function toggleClass(element, className) {
    // Simplified method to toggle class without manual checks
    $(element).toggleClass(className, !$(element).hasClass(className));
}

function main() {
    $('.option').click(function () {
        let $element = $(this).parent().find('.submenu-options');
        toggleClass($element, 'total-heigth');

        // Direct comparison using .is()
        if ($(this).is($('.name-option').first())) {
            toggleClass(this, 'high-margin');
        }
    });

    $('.menu-options .option').hover(function () {
        if (!menuClicked && screen.width >= 700) {
            toggleClass($(this).children('.submenu'), 'out');
        }
    });

    $('.menu-options .option').click(function () {
        let $submenu = $(this).children('.submenu');
        menuClicked = !$submenu.hasClass('clicked');
        toggleClass($submenu, 'clicked');
    });

    $('.submenu-options a').hover(function () {
        toggleClass($(this).children('.border'), 'total-border');
    });

    $('#open-menu-mobile').click(function () {
        if ($('#background-menu-mobile').hasClass('in')) {
            closeMobileMenu();
        } else {
            openMobileMenu();
        }
    });

    $('.submenu-option-mobile').hover(function () {
        toggleClass($(this).children('.border-mobile'), 'full-width');
    });

    $('#up-button').click(function (e) {
        e.preventDefault();
        $('html, body').animate({scrollTop: 0}, 'slow');
    });
}

function openMobileMenu() {
    $('#background-menu-mobile').addClass('in');
    $('#open-menu-mobile').children('.menu-icon').addClass('white');
    $('#open-menu-mobile').addClass('close-menu-button');

    let $lines = $("#open-menu-mobile span");
    $lines.eq(0).addClass('rotate-left absolute');
    $lines.eq(1).addClass('out');
    $lines.eq(2).addClass('rotate-right');
}

function closeMobileMenu() {
    $('#background-menu-mobile').removeClass('in');
    $('#open-menu-mobile').children('.menu-icon').removeClass('white');
    $('#open-menu-mobile').removeClass('close-menu-button');

    let $lines = $("#open-menu-mobile span");
    $lines.eq(0).removeClass('rotate-left absolute');
    $lines.eq(1).removeClass('out');
    $lines.eq(2).removeClass('rotate-right');
}

$(document).on('turbolinks:load', function() {
  menuClicked = false
  main();
});

